/*
  AddDN.js - Displays form for adding a new Dedicated Number to a user

  Author: David Mackenzie (copying directly from Kyle Combeer (2019))
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col, Table, Modal } from 'react-bootstrap';

// COMPONENTS
//import CountryPicker from '../../../components/CountryPicker';
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import UserRequest from './UserRequest';
const userRequest = new UserRequest();

export default class UserAddDN extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function to call when handling errors
    */

    this.state = {
      error: null,
      hasError: false,
      available: [],
      desired: [],
      dedicated: null, // dedicated numbers
      undesired: {},
      edits: {},
      isSearching: false, // should toggle while waiting for twilio response.
      isProvisioning: false,
      isReleasing: false,
      legacyReleaseCheck: false,
      selected: 0, // number of numbers selected for provisioning?
      campaigns: null,
    };
  }

  async componentDidMount() {
    //console.log('AddDN Test');
    // console.log(this.props);
    // console.log(this.state);

    let { errorHandler, user } = this.props;
    //console.log(user);
    let dedicatedNumbers = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    let dns = dedicatedNumbers;

    let ud = {};
    ud.numbers = [];
    let campaigns = await userRequest.getCustomerCampaignData(
      user.customer_id,
      errorHandler
    );
    //console.log(user);
    return this.setState({
      available: [],
      desired: [],
      undesired: ud,
      dedicated: dns,
      campaigns: campaigns,
    });
  }

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Handles any changes from the form
  */
  handleChange = (e) => {
    let { edits, desired, undesired } = this.state;
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    console.log('handleChange called');

    if (target.type === 'checkbox') {
      console.log('Checked a checkbox');
      console.log('target:', target);

      if (target.name === 'provision') {
        if (target.checked) {
          desired.push(target.value);
        } else {
          let pos = desired.indexOf(target.value);
          desired.splice(pos, 1);
        }
        console.log('desired:', desired);
      }

      if (target.name === 'release') {
        let udItem = {};
        udItem.device_id = target.id;
        udItem.phoneNumber = target.value;

        if (target.checked) {
          undesired.numbers.push(udItem);
        } else {
          let pos = undesired.numbers.indexOf(udItem);
          undesired.numbers.splice(pos, 1);
        }
        console.log('undesired', undesired);
      }
    }

    edits[name] = value;
    console.log('Edits:', edits);

    return this.setState({
      edits: edits,
      desired: desired,
      undesired: undesired,
      hasError: false,
      error: ''
    });
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = () => {
    let isValidForm = false;
    let errText = '';
    let { edits } = this.state;

    // Below is an example of normal field verification...
    // if (!edits.user_name) {
    //   isValidForm = false;
    //   errText = 'Requires a User Name.'
    // }

    console.log('edits.prefix: ', edits.prefix);
    if (edits.prefix === undefined) {
      isValidForm = false;
      errText = 'Requires a 3-digit prefix.';
    } else {
      if (edits.prefix.length < 3 || edits.prefix.length > 3) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
      if (isNaN(edits.prefix)) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
    }

    // If no error text, we have a valid form
    if (!errText) isValidForm = true;

    this.setState({
      hasError: !isValidForm,
      error: errText,
    });

    return {
      isValidForm: isValidForm,
      formError: errText,
    };
  };

  /*
    Generates the Lander section
  */
  generateLander = () => {
    return (
      <Fragment>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>Back to User</span>
        </p>
        <div className="flex">
          <h4>{formattedMessages.addDN}</h4>
          {this.generateReleaseButton()}
        </div>
      </Fragment>
    );
  };

  /*
    Generates the Save Button for the form based on the state
  */
  generateSearchButton = () => {
    // If in Search State
    if (this.state.isSearching) return <p>Searching...</p>;

    return (
      <Button
        type="button"
        onClick={this.handleSearch}
        className="maxHeight"
        variant="primary"
      >
        Search for available numbers
      </Button>
    );
  };

  generateProvisionButton = () => {
    // If in Provisioning State
    if (this.state.isProvisioning) return <p>Provisioning...</p>;

    let { desired } = this.state;
    if (desired.length <= 0) return;

    return (
      <Button
        type="button"
        onClick={this.handleProvision}
        className="maxHeight"
        variant="primary"
      >
        Provision selected
      </Button>
    );
  };

  generateReleaseButton = () => {
    // If in Releasing State
    if (this.state.isReleasing)
      return <p className="flexRight">Releasing...</p>;

    let { undesired } = this.state;
    console.log('undesired:', undesired);
    let nums = undesired.numbers;
    if ((nums ? nums.length : 0) <= 0) return;

    return (
      <Button
        type="button"
        onClick={this.handleRelease}
        className="flexRight maxHeight"
        variant="primary"
      >
        Release selected
      </Button>
    );
  };

  generateWarningModal = () => {
    return (
      <Modal
        show={this.state.legacyReleaseCheck}
        onHide={() => {
          this.setState({ legacyReleaseCheck: false, isReleasing: false });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.modalWarning}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{formattedMessages.modalBody}</Modal.Body>
        <Modal.Footer>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={() => this.handleRelease(false)}
          >
            {formattedMessages.modalYes}
          </Button>
          <Button
            className="no-button btn btn-secondary"
            variant="secondary"
            onClick={() => {
              this.setState({ legacyReleaseCheck: false, isReleasing: false });
            }}
          >
            {formattedMessages.modalCancel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Handles clicking the Add Dedicated Number Button
  */
  handleSearch = async () => {
    let result = this.validateForm();
    console.log(result);
    if (!result.isValidForm) {
      this.generateErrorText();

      console.log('form Validation Failed');
      return;
    }
    let data = {};
    data.prefix = this.state.edits.prefix;
    data.country = this.state.edits.country || 'US';

    this.toggleSearching();
    // console.log(data);
    //let { countryCode, prefix } = this.state;
    let { errorHandler /*user*/ } = this.props;
    let newNumbersObject = await userRequest.getTwilioProvisional(
      data,
      errorHandler
    );

    // console.log('handleSearch1: ');
    // console.log(newNumbersObject);
    if (newNumbersObject === 'undefined') {
      console.log('Error retrieving numbers from Twilio');
      this.props.callBack(true);
    } else {
      this.setState({
        available: newNumbersObject,
        desired: [],
      });
      //this.generateAvailableTable();
      console.log('handleSearch2: ', this.state.available);
      this.clearCheckboxes('provision');
      this.toggleSearching();
    }
  };

  clearCheckboxes(name) {
    var elements = document.getElementsByClassName(
      'form-check-input position-static'
    );
    for (var i = 0, len = elements.length; i < len; i++) {
      if ((elements[i] ? elements[i].name : null) === name)
        elements[i].checked = false;
    }
  }

  handleProvision = async () => {
    console.log('handleProvision called');
    this.toggleProvisioning();

    let { errorHandler, user } = this.props;
    let { desired, edits } = this.state;
    let data = {};
    data.numbers = desired;
    data.customerUserId = user.customer_user_id;
    data.customerId = user.customer_id;
    data.templateDeviceTn = '+18572142673';
    data.campaignId = edits.campaignId;
    console.log('data: ', data);
    let response = await userRequest.provisionTwilioNumbers(data, errorHandler);
    console.log('Provision response:', response);

    response = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    console.log('Dedicated after provision:', response);
    if (edits?.campaignId) {
      delete edits.campaignId;
    }

    this.clearCheckboxes('provision');
    this.toggleProvisioning();

    return this.setState({
      dedicated: response,
      available: [],
      desired: [],
      edits: edits,
    });
  };

  handleRelease = async (checkFlag) => {
    console.log('handleRelease called');
    this.toggleReleasing(true);
    this.setState({ legacyReleaseCheck: false });
    let { errorHandler, user } = this.props;
    let { undesired, dedicated } = this.state;

    console.log('undesired:', undesired);

    if (checkFlag) {
      for (let i = 0; i < undesired.numbers.length; i++) {
        let undesiredNumber = undesired.numbers[i];
        // To get the device info of the number to be released.
        let deviceIndex = dedicated.findIndex((element) => {
          return element.device_id === undesiredNumber.device_id;
        });

        // If it didn't find the dedicated number with the selected device id, there has been an error somewhere.
        if (deviceIndex !== -1) {
          let legacyCheck = dedicated[deviceIndex].device_name?.indexOf('AWS');
          if (legacyCheck === -1 || legacyCheck === undefined) {
            return this.setState({
              legacyReleaseCheck: true,
            });
          }
        }
      }
    }
    console.log('data:', undesired);
    await userRequest.releaseTwilioNumbers(undesired, errorHandler);

    let response = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    console.log('Dedicated after release:', response);

    let ud = {};
    ud.numbers = [];

    this.clearCheckboxes('release');
    this.toggleReleasing(false);

    return this.setState({
      dedicated: response,
      undesired: ud,
    });
  };

  toggleSearching() {
    let toggle = !this.state.isSearching;
    return this.setState({
      isSearching: toggle,
    });
  }

  toggleProvisioning() {
    let toggle = !this.state.isProvisioning;
    return this.setState({
      isProvisioning: toggle,
    });
  }

  toggleReleasing(target) {
    let toggle = !this.state.isReleasing;
    return this.setState({
      isReleasing: target || toggle,
    });
  }

  generateAvailableTable = () => {
    let htmlRows = [];
    let { available } = this.state;
    //let { user } = this.props;

    console.log('generateAvailableTable:', available);

    if (available === undefined || !available || available.length === 0) {
      return;
    }
    available.forEach((item) => {
      //console.log(item);
      htmlRows.push(
        <tr style={{ textAlign: 'center' }}>
          <td>{item.friendlyName}</td>
          <td>{item.locality}</td>
          <td>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                value={item.phoneNumber}
                name="provision"
                class="provision-checks"
              />
            </Col>
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.generateCampaignDropdown()}
        <Table style={{ marginTop: '20px' }}>
          <thead>
            <tr>
              <td className="medium tableTop column-left">Phone Number</td>
              <td className="medium tableTop column-left">Locality</td>
              <td className="medium tableTop column-left">Provision?</td>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
      </div>
    );
  };

  generateDedicatedNumbersTable = () => {
    let htmlRows = [];
    let { dedicated } = this.state;

    if (!dedicated) {
      return <p>Loading...</p>;
    }
    if (dedicated.length === 0) {
      return <p>No result found</p>;
    }

    dedicated.forEach((item) => {
      if (item !== null) {
        htmlRows.push(
          <tr style={{ textAlign: 'center' }}>
            <td className="column-left textLeft">{item.device_name}</td>
            <td className="textLeft">{item.campaignId}</td>
            <td className="column-right">
              <Col sm={8}>
                <Form.Check
                  type="checkbox"
                  id={item.device_id}
                  value={item.reply_tn}
                  name="release"
                />
              </Col>
            </td>
          </tr>
        );
      }
    });

    return (
      <Table style={{ marginTop: '20px' }} className="table-new">
        <thead>
          <tr>
            <th className="medium tableTop column-left">Dedicated Number(s)</th>
            <th className="medium tableTop column-left">Campaign</th>
            <th className="medium tableTop column-left">Release?</th>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  };

  /*
  Generates the Template Device Options
*/
  generateTemplateOptions = () => {
    let options = [];
    //let { centres, edits } = this.state;
    //let { user } = this.props;

    // Push unassigned option
    options.push(
      <option key={'template_device_id'} value={'+18572142673'}>
        {'Twilio Canada #3301 TxtMe (RO Ian)'}
      </option>
    );

    return (
      <Form.Group as={Row} controlId="template_device_id">
        <Form.Label column sm={2} className="info-label">
          Template Device:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name="template_device_id"
            defaultValue={'+18572142673'}
          >
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  generateCampaignDropdown = () => {
    let { campaigns } = this.state;

    if (!campaigns) return null;

    let options = [
      <option key={'None'} value={undefined}>
        {'None'}
      </option>,
    ];

    for (let i = 0; i < campaigns.length; i++) {
      // Push unassigned option
      options.push(
        <option key={i} value={campaigns[i].campaign_id}>
          {campaigns[i].campaign_id}
        </option>
      );
    }

    return (
      <Form.Group as={Row} controlId="campaignId">
        <Form.Label column sm={2} className="info-label">
          Provision against campaign:
        </Form.Label>
        <Col sm={8}>
          <Form.Control as="select" name="campaignId" defaultValue={'None'}>
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateTextFormGroup = (label, controlId, isRequired, setType) => {
    let type = 'text';
    if (setType) type = setType;
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            required={isRequired || true}
            name={controlId}
            autoComplete="new-password"
            type={type}
          />
        </Col>
      </Form.Group>
    );
  };

    /*
    Generates the Country Picker Options
  */
    generateCountryPicker = () => {
      let options = [];
  
      // Push unassigned option
      options = [
        <option key={'country1'} value={'US'}>
          {'United States'}
        </option>,
        <option key={'country2'} value={'CA'}>
          {'Canada'}
        </option>
        ];
  
      return (
        <Form.Group as={Row} controlId="country">
          <Form.Label column sm={2} className="info-label">
            Country:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              name="country"
              defaultValue={'US'}
            >
              {options}
            </Form.Control>
          </Col>
        </Form.Group>
      );
    };

  generateForm = () => {
    //let { edits } = this.state;
    //let { user } = this.props;

    return (
      <Form onChange={this.handleChange}>
        {this.generateDedicatedNumbersTable()}
        {this.generateCountryPicker()}
        {this.generateTextFormGroup('* Prefix', 'prefix', true)}
        {this.generateTemplateOptions()}
        {this.generateErrorText()}
        <div className="flex">
          {this.generateSearchButton()}
          {this.generateProvisionButton()}
        </div>
        {this.generateAvailableTable()}
      </Form>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        <br />
        {this.generateWarningModal()}
        {this.generateForm()}
      </Fragment>
    );
  }
}
