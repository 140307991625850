/*
  SingleView.js - Admin Tariff Scheme Single View

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';

// COMPONENTS
import TariffPlanList from './Plan/List';
import TariffPlanSingle from './Plan/Single';
import ErrorText from '../../../components/ErrorText';

// NETWORK
import TariffRequest from './TariffRequest';
import formattedMessages from '../Provider/FormattedMessages';

// INSTANTIATION
const tariffRequest = new TariffRequest();

export default class TariffSchemeSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffScheme: Tariff Scheme object (optional)
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new Carrier,
        - errorHandler: A function to pass errors to
    */

    console.log('Have Tariff Scheme props:', this.props);

    this.state = {
      isSaving: false,
      isEditing: false,
      edits: {},
      types: [],
      statuses: [],
      tariffPlans: [],
      showSingleTariffPlan: null,
      addNewTariffPlan: false,
      hasError: false,
      errorMessage: '',
      isLoading: false,
      showConfirmation: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { tariffScheme, errorHandler, selectedPlanId } = this.props;
    let schemeStatusesAndTypes = await tariffRequest.getSchemeStatusTypes(
      errorHandler
    );
    let tariffSchemeId = tariffScheme ? tariffScheme.tariff_scheme_id : null;
    if (tariffScheme) {
      let stateObj = {
        tariffPlans: await tariffRequest.getTariffPlans(
          tariffSchemeId,
          errorHandler
        ),
      };
      if (selectedPlanId) {
        const selectedPlan = stateObj.tariffPlans.find(
          (e) => e.tariff_plan_id === selectedPlanId
        );
        stateObj.showSingleTariffPlan = selectedPlan;
      }
      this.setState(stateObj);
    }

    return this.setState({
      types: schemeStatusesAndTypes.scheme_types,
      statuses: schemeStatusesAndTypes.scheme_statuses,
      isLoading: false,
    });
  }

  backButtonClick = async (refreshFlag) => {
    let defaultState = {
      addNewTariffPlan: false,
      showSingleTariffPlan: null,
    };
    if (refreshFlag) {
      this.setState({ isLoading: true });
      let tariffSchemeId = this.props.tariffScheme
        ? this.props.tariffScheme.tariff_scheme_id
        : null;
      let tariffPlans = await tariffRequest.getTariffPlans(
        tariffSchemeId,
        this.props.errorHandler
      );
      defaultState.tariffPlans = tariffPlans;
      defaultState.isLoading = false;
    }
    return this.setState(defaultState);
  };

  addNewTariffPlan = () => {
    return this.setState({
      addNewTariffPlan: true,
    });
  };

  showSingleTariffPlan = (tariffPlan) => {
    return this.setState({
      showSingleTariffPlan: tariffPlan,
    });
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit');
      e.preventDefault();
      let data = this.state.edits;

      // Have new Object, create
      if (this.props.isNew) {
        if (
          !data.tariff_scheme_name ||
          (data.tariff_scheme_name && data.tariff_scheme_name.trim() === '')
        ) {
          return this.setState({
            hasError: true,
            errorMessage: 'Require a Tariff Scheme Name.',
          });
        }
        if (!data.scheme_type) {
          return this.setState({
            hasError: true,
            errorMessage: 'Select a Scheme Type.',
          });
        }
        if (!data.scheme_status) {
          return this.setState({
            hasError: true,
            errorMessage: 'Select a Scheme Status.',
          });
        }
        this.setState({
          isSaving: true,
          hasError: false,
          errorMessage: '',
        });
        let result = await tariffRequest.createNewTariffScheme(
          data,
          this.props.errorHandler
        );
        if (!result.error) this.props.backButtonClick(true);
        else this.setState({ isSaving: false });
      }
      // Editing object, update
      else {
        await tariffRequest.updateTariffScheme(
          this.props.tariffScheme.tariff_scheme_id,
          data,
          this.props.errorHandler
        );
        this.props.backButtonClick(true);
      }
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  /*
    Handles Delete of Country Prefix Map
  */
  handleDelete = async () => {
    this.handleCloseConfirmation();
    await tariffRequest.deleteTariffSchemeById(
      this.props.tariffScheme.tariff_scheme_id,
      this.props.errorHandler
    );
    this.props.backButtonClick(true);
    return this.setState({
      showConfirmation: false,
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    let { showSingleTariffPlan, addNewTariffPlan } = this.state;
    // If showing other pages, return nothing here
    if (showSingleTariffPlan || addNewTariffPlan) return null;

    return (
      <div key="TariffSchemeShow" className="lander">
        <p onClick={() => this.props.backButtonClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Tariff Scheme Information</h3>
      </div>
    );
  };

  generateTypeSelect = () => {
    let { types } = this.state;
    let typeList = [];
    if (this.props.isNew) {
      typeList.push(
        <option selected disabled hidden key={null} value={null}>
          {'Select One'}
        </option>
      );
    }
    for (let i = 0; i < types.length; i++) {
      typeList.push(
        <option key={types[i].code} value={types[i].code}>
          {types[i].meaning}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="scheme_type"
        value={this.state.edits.code}
      >
        {typeList}
      </Form.Control>
    );
  };

  generateStatusSelect = () => {
    let { statuses } = this.state;
    let statusList = [];
    if (this.props.isNew) {
      statusList.push(
        <option selected disabled hidden key={null} value={null}>
          {'Select One'}
        </option>
      );
    }
    for (let i = 0; i < statuses.length; i++) {
      statusList.push(
        <option key={statuses[i].code} value={statuses[i].code}>
          {statuses[i].meaning}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="scheme_status"
        value={this.state.edits.code}
      >
        {statusList}
      </Form.Control>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={7}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateErrorText = () => {
    return (
      <ErrorText
        hasError={this.state.hasError}
        errorText={this.state.errorMessage}
      />
    );
  };

  generateFormButtons = () => {
    if (this.state.isSaving) return <p>Saving...</p>;

    return (
      <Fragment>
        <Button
          className="maxHeight"
          variant="primary"
          onClick={this.handleSubmit}
        >
          Save
        </Button>
        {this.generateEditButton()}
      </Fragment>
    );
  };

  generateTariffPlanList = () => {
    let { isLoading } = this.state;

    return (
      <TariffPlanList
        tariffPlans={this.state.tariffPlans}
        showSingleTariffPlan={this.showSingleTariffPlan}
        addNewTariffPlan={this.addNewTariffPlan}
        isLoading={isLoading}
      ></TariffPlanList>
    );
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  showConfirmationDialog = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deletion Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete the tariff scheme?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={this.handleDelete}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  generateBody = () => {
    let { tariffScheme, isNew } = this.props;
    let { isEditing, showSingleTariffPlan, addNewTariffPlan } = this.state;

    if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="tariff_scheme_name">
              <Form.Label column sm={2} className="info-label">
                * Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="tariff_scheme_name" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="scheme_type">
              <Form.Label column sm={2} className="info-label">
                * Type:
              </Form.Label>
              <Col sm={8}>{this.generateTypeSelect()}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="scheme_status">
              <Form.Label column sm={2} className="info-label">
                * Status:
              </Form.Label>
              <Col sm={8}>{this.generateStatusSelect()}</Col>
            </Form.Group>
            {this.generateErrorText()}
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
          </Form>
          {this.generateFormButtons()}
        </Fragment>
      );
    } else if (showSingleTariffPlan || addNewTariffPlan) {
      return (
        <TariffPlanSingle
          tariffScheme={tariffScheme}
          tariffPlan={showSingleTariffPlan}
          isNew={addNewTariffPlan}
          backButtonClick={this.backButtonClick}
        ></TariffPlanSingle>
      );
    }
    // Display data if not editing
    else if (tariffScheme && !this.state.isEditing) {
      return (
        <Fragment>
          <Form>
            {this.generateFormGroup(
              '* Name:',
              tariffScheme.tariff_scheme_name,
              'tariff_scheme_name'
            )}
            {this.generateFormGroup(
              '* Type:',
              tariffScheme.scheme_type_name,
              'scheme_type'
            )}
            {this.generateFormGroup(
              '* Status:',
              tariffScheme.scheme_status_name,
              'scheme_status'
            )}
          </Form>
          {this.generateEditButton()}
          <Button
            className="cancelUserType btn-red btn btn-danger"
            onClick={this.showConfirmationModal}
          >
            Delete
          </Button>
          <br />
          <br />
          {this.generateTariffPlanList()}
          {this.showConfirmationDialog()}
        </Fragment>
      );
    } else if (tariffScheme && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="tariff_scheme_name">
              <Form.Label column sm={2} className="detail-label">
                <b>* Name:</b>
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="tariff_scheme_name"
                  type="text"
                  defaultValue={tariffScheme.tariff_scheme_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="scheme_type">
              <Form.Label column sm={2} className="detail-label">
                <b>* Type:</b>
              </Form.Label>
              <Col sm={8}>{this.generateTypeSelect()}</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="scheme_status">
              <Form.Label column sm={2} className="detail-label">
                <b>* Status:</b>
              </Form.Label>
              <Col sm={8}>{this.generateStatusSelect()}</Col>
            </Form.Group>
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
            {this.generateEditButton()}
          </Form>
        </Fragment>
      );
    }

    return <span>(Tariff Single) No Data</span>;
  };

  render() {
    return (
      <div className="Home carrier">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
