/*
  InboxRequests.js -  InboxRequests Networking File

  Contains networking functionality for  Inbox Component

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// UTILS
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class InboxRequests {
  /*
    Fetches sent data
  */
  getInbox = async (fromDate, toDate, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/replies/?from_date=${fromDate}&to_date=${toDate}&type=SMS`;
      const options = { method: 'GET' };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
      return [];
    }
  };

  /*
    Gets Sent Items based on search criteria (if provided)
  */
  searchInboxItems = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/replies/`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      console.log('searchObject in searchSentItems: ', searchObject);
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };
}
