/*
  FormattedMessages.js - FormattedMessages users/administratorscomponent

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  admins: (
    <FormattedMessage
      id="Global.Admins"
      defaultMessage="Administrators"
    ></FormattedMessage>
  ),
  backToAdmin: (
    <FormattedMessage
      id="Global.backToAdmin"
      defaultMessage="Back to Administrator List"
    ></FormattedMessage>
  ),
  adminInfo: (
    <FormattedMessage
      id="Global.adminInfo"
      defaultMessage="Administrator Information"
    ></FormattedMessage>
  ),
  pleaseFill: (
    <FormattedMessage
      id="Global.pleaseFill"
      defaultMessage="* Please fill out both the fields"
    ></FormattedMessage>
  ),
  name: (
    <FormattedMessage id="Global.Name" defaultMessage="Name"></FormattedMessage>
  ),
  department: (
    <FormattedMessage
      id="Global.Department"
      defaultMessage="Department"
    ></FormattedMessage>
  ),
  accessLevel: (
    <FormattedMessage
      id="Global.AccessLevel"
      defaultMessage="* Access Level"
    ></FormattedMessage>
  ),
  position: (
    <FormattedMessage
      id="Global.Position"
      defaultMessage="Position"
    ></FormattedMessage>
  ),
  password: (
    <FormattedMessage
      id="Global.Password"
      defaultMessage="Password"
    ></FormattedMessage>
  ),
  confirmPassword: (
    <FormattedMessage
      id="Global.ConfirmPassword"
      defaultMessage="Confirm Password"
    ></FormattedMessage>
  ),
  receivedNews: (
    <FormattedMessage
      id="Global.ReceivedNews"
      defaultMessage="Received News"
    ></FormattedMessage>
  ),
  phone: (
    <FormattedMessage
      id="Global.Phone"
      defaultMessage="Phone"
    ></FormattedMessage>
  ),
  cell: (
    <FormattedMessage id="Global.Cell" defaultMessage="Cell"></FormattedMessage>
  ),
  fax: (
    <FormattedMessage id="Global.Fax" defaultMessage="Fax"></FormattedMessage>
  ),
  email: (
    <FormattedMessage
      id="Global.Email"
      defaultMessage="Email"
    ></FormattedMessage>
  ),
  address: (
    <FormattedMessage
      id="Global.Address"
      defaultMessage="Address"
    ></FormattedMessage>
  ),
  city: (
    <FormattedMessage id="Global.City" defaultMessage="City"></FormattedMessage>
  ),
  postcode: (
    <FormattedMessage
      id="Global.Postcode"
      defaultMessage="Postcode"
    ></FormattedMessage>
  ),
  state: (
    <FormattedMessage
      id="Global.State"
      defaultMessage="State"
    ></FormattedMessage>
  ),
  country: (
    <FormattedMessage
      id="Global.Country"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  billingContactType: (
    <FormattedMessage
      id="Global.BillingContactType"
      defaultMessage="Billing Contact Type"
    ></FormattedMessage>
  ),
  primary: (
    <FormattedMessage
      id="Global.Primary"
      defaultMessage="Primary"
    ></FormattedMessage>
  ),
  secondary: (
    <FormattedMessage
      id="Global.Secondary"
      defaultMessage="Secondary"
    ></FormattedMessage>
  ),
  na: <FormattedMessage id="Global.NA" defaultMessage="N/A"></FormattedMessage>,
  salesContactType: (
    <FormattedMessage
      id="Global.SalesContactType"
      defaultMessage="Sales Contact Type"
    ></FormattedMessage>
  ),
  technicalContactType: (
    <FormattedMessage
      id="Global.TechnicalContactType"
      defaultMessage="Technical Contact Type"
    ></FormattedMessage>
  ),
  save: (
    <FormattedMessage id="Global.Save" defaultMessage="Save"></FormattedMessage>
  ),
  aboutToPermDeleteThisAdmin: (
    <FormattedMessage
      id="Global.AboutToPermDeleteThisAdmin"
      defaultMessage="You are about to permanently delete this Administrator, are you sure you want to do this?"
    ></FormattedMessage>
  ),
  cancel: (
    <FormattedMessage
      id="Global.Cancel"
      defaultMessage="Cancel"
    ></FormattedMessage>
  ),
  deleteAdmin: (
    <FormattedMessage
      id="Global.Delete Admin"
      defaultMessage="Delete Admin"
    ></FormattedMessage>
  ),
  addNew: (
    <FormattedMessage
      id="Global.AddNew"
      defaultMessage="Add New"
    ></FormattedMessage>
  ),
  AddAdminLandingText: (
    <FormattedMessage
      id="Global.AddAdminLandingText"
      defaultMessage="Add New Administrator"
    ></FormattedMessage>
  ),
  errTextName: (
    <FormattedMessage
      id="Global.ErrTextName"
      defaultMessage="Requires a Name."
    />
  ),
  errTextPhoneNumber: (
    <FormattedMessage
      id="Global.ErrTextPhoneNumber"
      defaultMessage="Requires a Contact Phone Number."
    />
  ),
  errFormatPhoneNumber: (
    <FormattedMessage
      id="Global.errFormatPhoneNumber"
      defaultMessage='Incorrect contact phone format, should only contain numbers and "+", eg: +0123456789/0123456789'
    />
  ),
  errTextMobileNumber: (
    <FormattedMessage
      id="Global.ErrTextMobileNumber"
      defaultMessage="Requires a Mobile Phone Number."
    />
  ),
  errFormatMobileNumber: (
    <FormattedMessage
      id="Global.errFormatMobileNumber"
      defaultMessage='Incorrect mobile phone format, should only contain numbers and "+", eg: +0123456789/0123456789'
    />
  ),
  errTextEmail: (
    <FormattedMessage
      id="Global.ErrTextEmail"
      defaultMessage="Requires an Email."
    />
  ),
  errTextEmailCheck: (
    <FormattedMessage
      id="Global.ErrTextEmailCheck"
      defaultMessage="The email provided is not in a valid format."
    />
  ),
  errTextCountry: (
    <FormattedMessage
      id="Global.ErrTextCountry"
      defaultMessage="Select a Country."
    />
  ),
  errTextAccessLevel: (
    <FormattedMessage
      id="Global.ErrTextAccessLevel"
      defaultMessage="Select Access Level"
    />
  ),
  errTextPassword: (
    <FormattedMessage
      id="Global.ErrTextPassword"
      defaultMessage="Requires a Password."
    />
  ),
  errCheckPasswordStrong: (
    <FormattedMessage
      id="Global.ErrCheckPasswordOne"
      defaultMessage="Password is not strong enough. Please ensure it is 8 or more characters long and contains at least one uppercase and one lowercase letter, a number and a symbol."
    />
  ),
  errCheckPassword: (
    <FormattedMessage
      id="Global.ErrCheckPassword"
      defaultMessage="Requires a password in both password fields."
    />
  ),
  errCheckPasswordMatch: (
    <FormattedMessage
      id="Global.ErrCheckPasswordMatch"
      defaultMessage="Passwords don't match."
    />
  ),
  modalTitle: (
    <FormattedMessage
      id="Global.ModalTitle"
      defaultMessage="User Upgrade Confirmation"
    />
  ),
  modalBody: (
    <FormattedMessage
      id="Global.ModalBody"
      defaultMessage="A user already exists with that email address. Do you want to upgrade that user to an Administrator? (Password will remain unchanged, but name and mobile number will be updated)"
    />
  ),
  modalYes: <FormattedMessage id="Global.ModalYes" defaultMessage="Yes" />,
  modalNo: <FormattedMessage id="Global.ModalNo" defaultMessage="No" />,
  delete: (
    <FormattedMessage
      id="Global.Delete"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  errContactExists: (
    <FormattedMessage
      id="Global.ContactExists"
      defaultMessage="An administrator with that email address already exists"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
