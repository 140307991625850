import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';

// IMGS
import logoImg from '../images/logo.png';

const displayNavigationBar = ({
  mobileView,
  mobileMenuOpen,
  accessLevel,
  accessType,
  navBarInfo,
  acting_names,
  handleImitationClear,
  handleLogout,
}) => {
  // If on /customer/verify page, don't display

  if (mobileView) {
    return (
      <Fragment>
        <div className="mobile-main-heading">
          <Link to="/websms/compose" className="inline">
            <img src={logoImg} alt="" height="35px" border="0" />
          </Link>
          {mobileMenuOpen ? (
            <button
              onClick={() => this.toggleMenuDisplay()}
              className="mobile-menu-icon close-cross"
            >
              close
            </button>
          ) : (
            <button
              onClick={() => this.toggleMenuDisplay()}
              className="mobile-menu-icon hamburger"
            >
              menu
            </button>
          )}
        </div>
      </Fragment>
    );
  }

  if (window.location.href.includes('/customer/verify')) return null;
  let navbarClass = `navbar_${process.env.REACT_APP_ENV || 'local'}`;

  // FormattedMessages
  let homeText = <FormattedMessage id="App.HomeLink" defaultMessage="Home" />;
  let webSMSText = (
    <FormattedMessage id="App.WebSMSLink" defaultMessage="CompleteSMS" />
  );

  return (
    <Fragment>
      <Navbar
        className={navbarClass}
        variant="dark"
        align="true"
        collapseOnSelect
      >
        <Navbar.Brand width="172px">
          <a href="/home">
            <img src={logoImg} alt="" height="35px" />
          </a>
        </Navbar.Brand>
        <Nav className="mr-auto">
          {generateLinkContainer('/home', homeText)}
          {generateLinkContainer('/websms/compose', webSMSText)}
          {displayAdminMenu(accessLevel, accessType)}
          {displayGlobalAdminMenu(accessLevel, accessType)}
        </Nav>
        {displayAdminImitationView(accessLevel, accessType, acting_names)}
        {displayDropDown(
          accessLevel,
          accessType,
          navBarInfo.customerName,
          navBarInfo.userEmail,
          navBarInfo.customerAccountNo,
          handleImitationClear,
          handleLogout
        )}
      </Navbar>
    </Fragment>
  );
};

/**
 * renders the dropdown for logout as well as ability to clear imitations for god admins
 */
function displayDropDown(
  accessLevel,
  accessType,
  customerName,
  userEmail,
  customerAccountNo,
  handleImitationClear,
  handleLogout
) {
  let logoutText = (
    <FormattedMessage id="App.LogoutLink" defaultMessage="Logout" />
  );
  let clearImitationText = (
    <FormattedMessage
      id="App.ClearImitationLink"
      defaultMessage="Clear Imitations"
    />
  );

  if (accessLevel === '3' && accessType === '3') {
    return (
      <Nav pullright="true">
        <NavDropdown
          alignRight
          title={'Login: ' + customerName + ' | ' + userEmail}
          id="collapsible-nav-dropdown"
        >
          <NavDropdown.ItemText>
            {'Account ID: ' + customerAccountNo}
          </NavDropdown.ItemText>
          <NavDropdown.Item onClick={handleImitationClear}>
            {clearImitationText}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={handleLogout}>
            {logoutText}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  } else {
    return (
      <Nav pullright="true">
        <NavDropdown
          alignRight
          title={'Login: ' + customerName + ' | ' + userEmail}
          id="collapsible-nav-dropdown"
        >
          <NavDropdown.ItemText>
            {'Account ID: ' + customerAccountNo}
          </NavDropdown.ItemText>
          <NavDropdown.Item onClick={handleLogout}>
            {logoutText}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    );
  }
}

/*
  displays Admin Menu if role matches
*/
function displayAdminMenu(accessLevel, accessType) {
  if (accessLevel <= '0' || accessType === '0') return null;

  let userText = <FormattedMessage id="App.UsersLink" defaultMessage="Users" />;
  let reportsText = (
    <FormattedMessage id="App.ReportsLink" defaultMessage="Reports" />
  );
  let billingText = (
    <FormattedMessage id="App.BillingLink" defaultMessage="Billing" />
  );

  return (
    <Fragment>
      {generateLinkContainer('/users/administrators', userText)}
      {generateLinkContainer('/reports/cost-center', reportsText)}
      {generateLinkContainer('/billing/invoices', billingText)}
    </Fragment>
  );
}

function displayGlobalAdminMenu(accessLevel, accessType) {
  let adminText = (
    <FormattedMessage id="App.AdminLink" defaultMessage="Administration" />
  );

  if (accessLevel === '3' && accessType === '3') {
    return (
      <Fragment>
        {generateLinkContainer('/admin/find-customer', adminText)}
      </Fragment>
    );
  }
}

/**
 * renders to display the imitated customer and provider, only for god admin.
 */
function displayAdminImitationView(accessLevel, accessType, acting_names) {
  if (accessLevel === '3' && accessType === '3' && acting_names) {
    return (
      <Nav>
        <div className="nav-admin-view">
          {'Provider: ' + acting_names.provider_name}
          <br />
          {'Customer: ' + acting_names.customer_name}
          <br />
        </div>
      </Nav>
    );
  }
  return null;
}

/*
  Convenience function to generate a Link Container
  for the Navbar
*/
function generateLinkContainer(linkTo, linkText) {
  // splitting both texts to compare tab link to url.
  let linkToTextNodes = linkTo.split('/');
  let pathNodes = window.location.pathname.split('/');

  // specific cases (that may no longer be relevant).
  if (linkText.props.defaultMessage === 'home') {
    linkToTextNodes[1] = 'home';
    if (pathNodes[2] === 'home') {
      pathNodes[1] = null;
    }
  } else if (linkText.props.defaultMessage === 'websms') {
    if (pathNodes[2] === 'home') {
      pathNodes[1] = null;
    }
  }

  // adds active as a class if the nav item matches the current url.
  let isActiveClassNames = 'nav-link';
  if (linkToTextNodes[1] === pathNodes[1]) {
    isActiveClassNames = 'active';
  }

  // generates navbar link containers.
  return (
    <LinkContainer to={linkTo}>
      <Nav.Link active={false} className={isActiveClassNames}>
        {linkText}
      </Nav.Link>
    </LinkContainer>
  );
}

export default displayNavigationBar;
