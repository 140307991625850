/*
  FormattedMessages.js - FormattedMessages for WebSMS component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';
const FormattedMessages = {
  loading: (
    <FormattedMessage id="App.LoadingText" defaultMessage="Loading..." />
  ),
  composeTitle: (
    <FormattedMessage id="Websms.ComposeTitle" defaultMessage="Compose" />
  ),
  bulkSendTitle: (
    <FormattedMessage id="Websms.bulkSendTitle" defaultMessage="Bulk Send" />
  ),
  inboxTitle: (
    <FormattedMessage id="Websms.InboxTitle" defaultMessage="Inbox" />
  ),
  sentTitle: <FormattedMessage id="Websms.SentTitle" defaultMessage="Sent" />,
  errorsTitle: (
    <FormattedMessage id="Websms.ErrorsTitle" defaultMessage="Errors" />
  ),
  templateTitle: (
    <FormattedMessage id="Websms.TemplateTitle" defaultMessage="Templates" />
  ),
  addressBookTitle: (
    <FormattedMessage
      id="Websms.AddressBookTitle"
      defaultMessage="Address Book"
    />
  ),
  distListTitle: (
    <FormattedMessage
      id="Websms.DistListTitle"
      defaultMessage="Distribution Lists"
    />
  ),
  settingsTitle: (
    <FormattedMessage id="Websms.SettingsTitle" defaultMessage="Settings" />
  ),
  dateTimeText: (
    <FormattedMessage id="Websms.DateTimeText" defaultMessage="Date/Time" />
  ),
  toText: <FormattedMessage id="Websms.ToText" defaultMessage="To" />,
  messageText: (
    <FormattedMessage id="Websms.MessageText" defaultMessage="Message" />
  ),
  statusText: (
    <FormattedMessage id="Websms.StatusText" defaultMessage="Status" />
  ),
  logout: <FormattedMessage id="Websms.Logout" defaultMessage="Logout" />,
  clear: (
    <FormattedMessage id="Websms.ClearFilters" defaultMessage="Clear Filters" />
  ),
  filterNote: (
    <FormattedMessage
      id="Websms.filterNoteInbox"
      defaultMessage="NOTE: Numbers should be entered as only digits, no spaces or
    +. All fields are case sensitive."
    />
  ),
};

export default FormattedMessages;
