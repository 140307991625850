// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col, Table, Modal } from 'react-bootstrap';

// COMPONENTS
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import CustomerRequest from './CustomerRequest';

const customerRequest = new CustomerRequest();

export default class ManagePoolNumber extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function to call when handling errors.
        - customer: An object containing the customer currently being edited.
        - callBack: A function to go back to viewing the regular customer edit page.
    */

    this.state = {
      error: null,
      hasError: false,
      available: [],
      desired: [],
      poolNumbers: null, // pool numbers
      undesired: {},
      edits: {},
      campaignEdits: {},
      isSearching: false, // should toggle while waiting for twilio response.
      isProvisioning: false,
      isReleasing: false,
      isChangingCampaign: false,
      selected: 0, // number of numbers selected for provisioning?
      campaigns: null,
      campaignId: undefined, // stores value of campaign dropdown when provisioning.
      templateDeviceTn: '+12049008550',
      legacyReleaseCheck: false,
    };
  }

  async componentDidMount() {
    let { errorHandler, customer } = this.props;

    // TODO: move into function to avoid code duplication.
    let poolNumbers = await customerRequest.getPoolNumbers(
      customer.customer_id,
      errorHandler
    );
    console.log('pool numbers:', poolNumbers);
    let toBeReleased = {};
    toBeReleased.numbers = [];

    let campaigns = await customerRequest.getCustomerCampaignData(
      customer.customer_id,
      errorHandler
    );

    return this.setState({
      available: [],
      desired: [],
      undesired: toBeReleased,
      poolNumbers: poolNumbers,
      campaigns: campaigns,
    });
  }

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Handles any changes from the form
  */
  handleChange = (e) => {
    let { edits, desired, undesired } = this.state;
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    console.log('handleChange called');

    if (target.type === 'checkbox') {
      console.log('Checked a checkbox');
      console.log('target:', target);

      if (target.name === 'provision') {
        if (target.checked) {
          desired.push(target.value);
        } else {
          let pos = desired.indexOf(target.value);
          desired.splice(pos, 1);
        }
        console.log('desired:', desired);
      }

      if (target.name === 'release') {
        let udItem = {};
        udItem.device_id = target.id;
        udItem.phoneNumber = target.value;

        if (target.checked) {
          undesired.numbers.push(udItem);
        } else {
          let pos = undesired.numbers.indexOf(udItem);
          undesired.numbers.splice(pos, 1);
        }
        console.log('undesired', undesired);
      }
    }

    if (name === 'campaignId') {
      return this.setState({
        campaignId: value,
      });
    }

    if (name === 'template_device_id') {
      return this.setState({
        templateDeviceTn: value,
      });
    }

    if (name === 'campaignChange') {
      let { poolNumbers, campaignEdits } = this.state;
      let index = poolNumbers.findIndex((e) => {
        if (e.device_id === target.id) {
          if (value === 'N/A' && e.campaignId !== undefined) return true;
          else if (value !== 'N/A' && value !== e.campaignId) return true;
        }
      });

      if (index !== -1) campaignEdits[target.id] = value;
      // If its the same campaign, then delete from the edits object.
      else delete campaignEdits[target.id];

      return this.setState({ campaignEdits: campaignEdits });
    }

    edits[name] = value;
    console.log('Edits:', edits);

    return this.setState({
      edits: edits,
      desired: desired,
      undesired: undesired,
      hasError: false,
      error: ''
    });
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = () => {
    let isValidForm = false;
    let errText = '';
    let { edits } = this.state;

    console.log('edits.prefix: ', edits.prefix);
    if (edits.prefix === undefined) {
      isValidForm = false;
      errText = 'Requires a 3-digit prefix.';
    } else {
      if (edits.prefix.length < 3 || edits.prefix.length > 3) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
      if (isNaN(edits.prefix)) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
    }

    // If no error text, we have a valid form
    if (!errText) isValidForm = true;

    this.setState({
      hasError: !isValidForm,
      error: errText,
    });

    return {
      isValidForm: isValidForm,
      formError: errText,
    };
  };

  /*
    Handles clicking the Add Pool Number Button
  */
  handleSearch = async () => {
    let result = this.validateForm();
    console.log(result);
    if (!result.isValidForm) {
      this.generateErrorText();

      console.log('form Validation Failed');
      return;
    }
    let data = {};
    data.prefix = this.state.edits.prefix;
    data.country = this.state.edits.country || 'US';

    this.toggleSearching();

    let { errorHandler } = this.props;
    let newNumbersObject = await customerRequest.getTwilioProvisional(
      data,
      errorHandler
    );

    if (newNumbersObject === 'undefined') {
      console.log('Error retrieving numbers from Twilio');
      this.props.callBack(true);
    } else {
      this.setState({
        available: newNumbersObject,
        desired: [],
      });

      this.clearCheckboxes('provision');
      this.toggleSearching();
    }
  };

  clearCheckboxes(name) {
    var elements = document.getElementsByClassName(
      'form-check-input position-static'
    );
    for (var i = 0, len = elements.length; i < len; i++) {
      if ((elements[i] ? elements[i].name : null) === name)
        elements[i].checked = false;
    }
  }

  changeCampaignForNumber = async () => {
    this.setState({
      isChangingCampaign: true,
    });
    let { campaignEdits } = this.state;
    let { errorHandler, customer } = this.props;

    let response = await customerRequest.changeTwilioNumbers(
      campaignEdits,
      customer.customer_id,
      errorHandler
    );

    let poolNumbers = await customerRequest.getPoolNumbers(
      customer.customer_id,
      errorHandler
    );
    console.log('Pool Numbers after provision:', response);

    this.setState({
      campaignEdits: {},
      poolNumbers: poolNumbers,
      isChangingCampaign: false,
    });
  };

  handleProvision = async () => {
    console.log('handleProvision called');
    this.toggleProvisioning();

    let { errorHandler, customer } = this.props;
    let { desired, campaignId, templateDeviceTn } = this.state;
    let data = {};
    data.numbers = desired;
    data.customerId = customer.customer_id;
    data.templateDeviceTn = templateDeviceTn;
    data.campaignId = campaignId;
    console.log('data: ', data);
    let response = await customerRequest.provisionTwilioNumbers(
      data,
      errorHandler
    );
    console.log('Provision response:', response);

    response = await customerRequest.getPoolNumbers(
      customer.customer_id,
      errorHandler
    );
    console.log('Pool Numbers after provision:', response);

    this.clearCheckboxes('provision');
    this.toggleProvisioning();

    return this.setState({
      poolNumbers: response,
      available: [],
      desired: [],
      campaignId: undefined,
    });
  };

  handleRelease = async (checkFlag) => {
    console.log('handleRelease called');
    this.toggleReleasing(true);
    this.setState({ legacyReleaseCheck: false });
    let { errorHandler, customer } = this.props;
    let { undesired, poolNumbers } = this.state;

    console.log('undesired:', undesired);

    if (checkFlag) {
      for (let i = 0; i < undesired.numbers.length; i++) {
        let undesiredNumber = undesired.numbers[i];
        // To get the device info of the number to be released.
        let deviceIndex = poolNumbers.findIndex((element) => {
          return element.device_id === undesiredNumber.device_id;
        });

        // If it didn't find the pool number with the selected device id, there has been an error somewhere.
        if (deviceIndex !== -1) {
          let legacyCheck =
            poolNumbers[deviceIndex].device_name?.indexOf('AWS');
          if (legacyCheck === -1 || legacyCheck === undefined) {
            return this.setState({
              legacyReleaseCheck: true,
            });
          }
        }
      }
    }

    console.log('data:', undesired);
    await customerRequest.releaseTwilioNumbers(undesired, errorHandler);

    let response = await customerRequest.getPoolNumbers(
      customer.customer_id,
      errorHandler
    );
    console.log('Pool Numbers after release:', response);

    let ud = {};
    ud.numbers = [];

    this.clearCheckboxes('release');
    this.toggleReleasing(false);

    return this.setState({
      poolNumbers: response,
      undesired: ud,
    });
  };

  toggleSearching() {
    let toggle = !this.state.isSearching;
    return this.setState({
      isSearching: toggle,
    });
  }

  toggleProvisioning() {
    let toggle = !this.state.isProvisioning;
    return this.setState({
      isProvisioning: toggle,
    });
  }

  toggleReleasing(target) {
    let toggle = !this.state.isReleasing;
    return this.setState({
      isReleasing: target || toggle,
    });
  }

  generateAvailableTable = () => {
    let htmlRows = [];
    let { available } = this.state;

    console.log('generateAvailableTable:', available);

    if (available === undefined || !available || available.length === 0) {
      return;
    }
    available.forEach((item) => {
      //console.log(item);
      htmlRows.push(
        <tr style={{ textAlign: 'center' }}>
          <td className="textLeft">{item.friendlyName}</td>
          <td className="textLeft">{item.locality}</td>
          <td>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                value={item.phoneNumber}
                name="provision"
                className="provision-checks"
              />
            </Col>
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.generateCampaignDropdown()}
        <Table style={{ marginTop: '20px' }} className="table-new">
          <thead>
            <tr>
              <th className="medium tableTop column-left">Phone Number</th>
              <th className="medium tableTop column-left">Locality</th>
              <th className="medium tableTop column-left">Provision?</th>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
      </div>
    );
  };

  generatePoolNumbersTable = () => {
    let htmlRows = [];
    let { poolNumbers, campaigns, campaignEdits, isChangingCampaign } =
      this.state;

    if (!poolNumbers) return <div>{formattedMessages.loading}</div>;

    if (poolNumbers.length === 0)
      return <div>{formattedMessages.noPoolNumbers}</div>;

    let options = [
      <option key={'N/A'} value={'N/A'}>
        {'N/A'}
      </option>,
    ];

    for (let i = 0; i < campaigns.length; i++) {
      // Push unassigned option
      options.push(
        <option key={i} value={campaigns[i].campaign_id}>
          {campaigns[i].campaign_id}
        </option>
      );
    }

    poolNumbers.forEach((item, i) => {
      if (item !== null) {
        let { device_name, device_id, campaignId, reply_tn } = item;

        // Creates dropdown select for the campaign if current campaign is valid for the new system.
        let campaignContent = (
          <Form.Control
            onChange={this.handleChange}
            as="select"
            name="campaignChange"
            defaultValue={campaignId || 'N/A'}
            id={device_id}
          >
            {options}
          </Form.Control>
        );

        // If campaign wasn't found for the customer in the new system, its probably legacy or broken.
        if (
          !campaigns ||
          campaigns.findIndex((e) => {
            if (e.campaign_id === campaignId || campaignId === undefined)
              return true;
          }) === -1
        ) {
          campaignContent = campaignId || 'N/A';
        }

        // Pushes rows for table.
        htmlRows.push(
          <tr key={i} style={{ textAlign: 'center' }}>
            <td className="column-left textLeft">{device_name}</td>
            <td className="textLeft">{campaignContent}</td>
            <td className="column-right">
              <Col sm={2} className="margin-auto">
                <Form.Check
                  type="checkbox"
                  id={device_id}
                  value={reply_tn}
                  name="release"
                />
              </Col>
            </td>
          </tr>
        );
      }
    });
    let { campaign, poolNumber, release, saveChanges, saving } =
      formattedMessages;

    let button = null;
    if (isChangingCampaign) {
      button = <p>{saving}</p>;
    } else if (campaignEdits && Object.keys(campaignEdits).length !== 0) {
      button = (
        <Button
          type="button"
          onClick={this.changeCampaignForNumber}
          className="flexRight maxHeight"
          variant="primary"
        >
          {saveChanges}
        </Button>
      );
    }
    return (
      <div>
        <Table style={{ marginTop: '20px' }} className="table-new">
          <thead>
            <tr>
              <th className="medium tableTop column-left">{poolNumber}</th>
              <th className="medium tableTop column-left">{campaign}</th>
              <th className="medium tableTop column-left column-right">
                {release}
              </th>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
        {button}
      </div>
    );
  };

  /*
    Generates the Lander section
  */
  generateLander = () => {
    return (
      <Fragment>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>Back to Customer</span>
        </p>
        <div className="flex">
          <h4>{formattedMessages.addPN}</h4>
          {this.generateReleaseButton()}
        </div>
      </Fragment>
    );
  };

  /*
    Generates the Save Button for the form based on the state
  */
  generateSearchButton = () => {
    // If in Search State
    if (this.state.isSearching) return <p>Searching...</p>;

    return (
      <Button
        type="button"
        onClick={this.handleSearch}
        className="maxHeight"
        variant="primary"
      >
        Search for available numbers
      </Button>
    );
  };

  generateProvisionButton = () => {
    // If in Provisioning State
    if (this.state.isProvisioning)
      return <p className="flexRight">Provisioning...</p>;

    let { desired } = this.state;
    if (desired.length <= 0) return;

    return (
      <Button
        type="button"
        onClick={this.handleProvision}
        className="flexRight maxHeight"
        variant="primary"
      >
        Provision selected
      </Button>
    );
  };

  generateReleaseButton = () => {
    // If in Releasing State
    if (this.state.isReleasing)
      return <p className="flexRight">Releasing...</p>;

    let { undesired } = this.state;
    console.log('undesired:', undesired);
    let nums = undesired.numbers;
    if ((nums ? nums.length : 0) <= 0) return;

    return (
      <Button
        type="button"
        onClick={() => this.handleRelease(true)}
        className="flexRight maxHeight"
        variant="primary"
      >
        Release selected
      </Button>
    );
  };

  generateCampaignDropdown = () => {
    let { campaigns } = this.state;

    if (!campaigns) return null;

    let options = [
      <option key={'None'} value={undefined}>
        {'None'}
      </option>,
    ];

    for (let i = 0; i < campaigns.length; i++) {
      // Push unassigned option
      options.push(
        <option key={i} value={campaigns[i].campaign_id}>
          {campaigns[i].campaign_id}
        </option>
      );
    }

    return (
      <Form.Group as={Row} controlId="campaignId">
        <Form.Label column sm={2} className="info-label">
          Provision against campaign:
        </Form.Label>
        <Col sm={8}>
          <Form.Control as="select" name="campaignId" defaultValue={'None'}>
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  generateWarningModal = () => {
    return (
      <Modal
        show={this.state.legacyReleaseCheck}
        onHide={() => {
          this.setState({ legacyReleaseCheck: false, isReleasing: false });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.modalWarning}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{formattedMessages.modalBody}</Modal.Body>
        <Modal.Footer>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={() => this.handleRelease(false)}
          >
            {formattedMessages.modalYes}
          </Button>
          <Button
            className="no-button btn btn-secondary"
            variant="secondary"
            onClick={() => {
              this.setState({ legacyReleaseCheck: false, isReleasing: false });
            }}
          >
            {formattedMessages.modalCancel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
  Generates the Template Device Options
*/
  generateTemplateOptions = () => {
    let options = [];

    // Push unassigned option
    options.push(
      <option key={'+12049008550'} value={'+12049008550'}>
        {'Twilio Canada #5651 Group (UKG Pool)'}
      </option>
    );

    return (
      <Form.Group as={Row} controlId="template_device_id">
        <Form.Label column sm={2} className="info-label">
          Template Device:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name="template_device_id"
            defaultValue={'+12049008550'}
          >
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateTextFormGroup = (label, controlId, isRequired, setType) => {
    let type = 'text';
    if (setType) type = setType;
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            required={isRequired || true}
            name={controlId}
            autoComplete="new-password"
            type={type}
          />
        </Col>
      </Form.Group>
    );
  };

    /*
    Generates the Country Picker Options
  */
    generateCountryPicker = () => {
      let options = [];
  
      // Push unassigned option
      options = [
        <option key={'country1'} value={'US'}>
          {'United States'}
        </option>,
        <option key={'country2'} value={'CA'}>
          {'Canada'}
        </option>
        ];
  
      return (
        <Form.Group as={Row} controlId="country">
          <Form.Label column sm={2} className="info-label">
            Country:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              name="country"
              defaultValue={'US'}
            >
              {options}
            </Form.Control>
          </Col>
        </Form.Group>
      );
    };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        <br />
        <Form onChange={this.handleChange}>
          {this.generatePoolNumbersTable()}
          <br />
          {this.generateCountryPicker()}
          {this.generateTextFormGroup('* Prefix', 'prefix', true)}
          {this.generateTemplateOptions()}
          {this.generateErrorText()}
          <div className="flex">
            {this.generateSearchButton()}
            {this.generateProvisionButton()}
          </div>
          <br />
          {this.generateAvailableTable()}
        </Form>
        {this.generateWarningModal()}
      </Fragment>
    );
  }
}
