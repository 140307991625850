/*
  Failed.js - Component to handle Failed SMS content

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';
// import moment from 'moment';

// COMPONENTS
import ListComponent from './List';
import WebSMSTabs from '../index';
import Single from './Single';

import Paginator from '../../../components/Paginator';
import DateRangeSelector from '../../../components/DateRangeSelector';

// CONFIG
import config from '../../../Config';

// I18N
import formattedMessages from '../FormattedMessages';

// NETWORK
import FailedRequest from './FailedRequest';
const failedRequest = new FailedRequest();

export default class Failed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: [],
      paginatedFailed: [],
      selected: null,
      error: null,
      fromDate: new Date(),
      toDate: new Date(),
      timeRange: null,
      fromFilter: null,
      toFilter: null,
      nameFilter: null,
      statusFilter: null,
      codeFilter: null,
      isSearching: false,
      pageNumber: 1,
      pageLength: config.defaultPL,
      currentlySearching: {},
      open: false,
      selectorKeyRefresh: true,
    };
  }

  /*
    Back button
  */
  backButtonClick = () => {
    return this.setState({
      selected: null,
    });
  };

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ isSearching: true });
      let searchParams = {};
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
      searchParams.timeRangeStart = fromDate;
      searchParams.timeRangeEnd = toDate;
      searchParams.fromFilter = this.state.fromFilter;
      searchParams.toFilter = this.state.toFilter;
      searchParams.nameFilter = this.state.nameFilter;
      searchParams.statusFilter = this.state.statusFilter;
      searchParams.codeFilter = this.state.codeFilter;
      searchParams.timeRange = timeRange;

      let failed = await failedRequest.searchFailedSms(
        searchParams,
        this.props.errorHandler
      );
      console.log('Got POST result:', failed);
      this.setState(
        {
          failed: failed,
          fromDate: searchParams.fromDate,
          toDate: searchParams.toDate,
          timeRange: timeRange,
          currentlySearching: {
            fromFilter: searchParams.fromFilter,
            toFilter: searchParams.toFilter,
            nameFilter: searchParams.nameFilter,
            statusFilter: searchParams.statusFilter,
            codeFilter: searchParams.codeFilter,
          },
          isSearching: false,
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        error: e.message,
        isSearching: false,
      });
    }
  };

  /*
    Handles the selection and display of a single Failed SMS
  */
  showSingleFailedSms = (sms) => {
    return this.setState({
      selected: sms,
    });
  };

  /*
    Generates the Lander for the Failed SMS Container
  */
  generateLander = () => {
    if (this.state.selected) return null;
    return (
      <div className="lander">
        <h3>{formattedMessages.errorsTitle}</h3>
      </div>
    );
  };

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  statusDropdown = () => {
    let list = [];
    let errorStatus = [
      'Blacklisted',
      'Cancelled',
      'Invalid Number',
      'Failed Delivery',
      'Too Many Retries',
    ];
    if (!this.state.statusFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Status
        </option>
      );
    for (let i = 0; i < errorStatus.length; i++) {
      list.push(
        <option key={errorStatus[i]} value={errorStatus[i]}>
          {errorStatus[i]}
        </option>
      );
    }

    return (
      <Form>
        <Form.Control
          as="select"
          name="statusFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  /* Generates the Search Tools for querying data */
  generateSearchTools = () => {
    // Have Sent SMS selected, hide search
    if (this.state.selected) return null;

    const { open, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let { fromFilter, toFilter, nameFilter, statusFilter, codeFilter } =
      currentlySearching || {};

    let filterText = [];
    let values = ['From:', 'To:', 'Name:', 'Status:', 'Code:'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [fromFilter, toFilter, nameFilter, statusFilter, codeFilter].map(
      (filter, i) => {
        if (filter)
          filterText.push(
            <span>
              {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
            </span>
          );
      }
    );

    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>

        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <p className="no-margin">{formattedMessages.filterNote}</p>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              From:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="fromFilter"
                                className="filter-control"
                                id="fromFilter"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              To:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="toFilter"
                                id="toFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Name:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="nameFilter"
                                className="filter-control"
                                id="nameFilter"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Name"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Status:
                            </Form.Label>
                          </td>
                          <td>
                            <div>{this.statusDropdown()}</div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Code:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="codeFilter"
                                className="filter-control"
                                id="codeFilter"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Code"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>

                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  /* redos call to API backend to get refreshed results */
  reloadPage = () => {
    if (this.props.customer && this.props.customer !== null) {
      let { fromDate, toDate, timeRange } = this.state;
      this.handleUserDataSearch(fromDate, toDate, timeRange);
    }
  };

  setPaginatedResults = () => {
    let { failed, pageNumber, pageLength } = this.state;
    this.setState({
      paginatedFailed: failed.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength - 1 + 1
      ),
    });
  };

  generateBody = () => {
    if (this.state.selected) {
      return (
        <Single
          sms={this.state.selected}
          backButtonClick={this.backButtonClick}
        />
      );
    } else if (this.state.isSearching) {
      return <span>Searching...</span>;
    } else if (
      this.state.failed.length === 0 ||
      this.state.paginatedFailed.length === 0
    ) {
      return <span>No results found.</span>;
    }

    // display list of results.
    let { paginatedFailed, pageLength } = this.state;
    console.log('Paginated Errors:', paginatedFailed);

    return (
      <div>
        <ListComponent
          dateFormats={this.props.dateFormats}
          list={paginatedFailed}
          showSingleFailedSms={this.showSingleFailedSms}
        />
        <Paginator
          itemCount={this.state.failed.length}
          changeEvent={this.changeEvent}
          pageLength={pageLength}
          editFunction={this.changePageLength}
          showTotal={'Total Error Messages Found'}
          toolTip={'Maximum of 10,000 records shown'}
        />
      </div>
    );
  };

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  /*
     Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.setState({
      selected: null,
      failed: [],
      selectorKeyRefresh: !this.state.selectorKeyRefresh,
    });
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      fromFilter: null,
      toFilter: null,
      nameFilter: null,
      statusFilter: null,
      codeFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  render() {
    return (
      <WebSMSTabs
        tab="errors"
        mobileMenuOpen={this.props.mobileMenuOpen}
        navigate={this.props.navigate}
        toggleMobileMenu={this.props.toggleMobileMenu}
        handlePageReset={this.handlePageReset}
      >
        <div className="Home">
          {this.generateLander()}
          <div className="inboxWrapper">
            {this.generateSearchTools()}
            {this.generateBody()}
          </div>
        </div>
      </WebSMSTabs>
    );
  }
}
