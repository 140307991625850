/*
  Edit.js - Displays form for editing a Customer User

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  FormCheck,
  Modal,
} from 'react-bootstrap';

// COMPONENTS
import CountryPicker from '../../../components/CountryPicker';
import LanguagePicker from '../../../components/LanguagePicker';
import ErrorText from '../../../components/ErrorText';
import CustomTooltip from '../../../components/CustomTooltip';

// I18N
import formattedMessages from './FormattedMessages';

// Dedicated Number Form
import AddDN from './AddDN';

// NETWORK
import UserRequest from './UserRequest';
import {
  validatePhoneNumber,
  validatePassword,
  validateSms,
} from '../../../utils/validate';
const userRequest = new UserRequest();

export default class UserAdd extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - user: The User to edit
        - errorHandler: A function to call when handling errors
    */

    this.state = {
      error: null,
      centres: [],
      dns: [],
      edits: { showPassword: false },
      addDN: false, // Display the Add Dedicated Number form (AddDN.js)
      isSaving: false,
      showConfirmation: false,
    };
  }

  async componentDidMount() {
    let { errorHandler, user } = this.props;
    let centres = await userRequest.getCentres(errorHandler);
    let dedicatedNumbers = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );

    this.setState({
      centres: centres,
      dns: dedicatedNumbers,
    });
  }

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Generates the Save Button for the form based on the state
  */
  generateFormButtons = () => {
    if (this.state.isSaving) return <p>Saving...</p>;

    return (
      <Fragment>
        <Button
          type="submit"
          className="maxHeight"
          variant="primary"
          onClick={this.handleSubmit}
        >
          Save
        </Button>
        <Button
          type="submit"
          className="cancelUserType btn-red"
          variant="danger"
          onClick={this.handleCancel}
        >
          Cancel User
        </Button>
      </Fragment>
    );
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationDialog = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancellation Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to cancel the user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={this.cancelUser}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Generates the Add Dedicated Number button within the Dedicated Numbers table.
  */
  generateDNButton = () => {
    let { accessLevel, accessType } = this.props;
    if (accessLevel === '3' && accessType === '3') {
      return (
        <Fragment>
          <Button className="maxHeight" onClick={() => this.handleAddDN()}>
            {formattedMessages.addDN}
          </Button>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  /*
    Handles any changes from the form
  */
  handleFormChange = (e) => {
    let { edits } = this.state;
    const { target } = e;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let { name } = target;

    if (name === 'formCountry') name = 'country_code_id';
    if (name === 'receipt_flag') value = value === true ? 'Y' : 'N';
    if (name === 'http_reply_flag') value = value === true ? 'Y' : 'N';
    if (name === 'last_sms_text_flag') value = value === true ? 'Y' : 'N';

    edits[name] = value;
    console.log(name, value);
    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles submitting the form
  */
  handleSubmit = (e) => {
    e.preventDefault();
    try {
      let { edits } = this.state;
      let { errorHandler } = this.props;
      console.log('edits:', edits);

      // Check phone number format.
      if (edits.user_tn && edits.user_tn !== '') {
        if (!validatePhoneNumber(edits.user_tn)) {
          return this.setState({
            hasError: true,
            error: formattedMessages.errCheckPhoneFormat,
          });
        }
      }
      // Check password format
      if (edits.password && edits.password !== '') {
        if (!validatePassword(edits.password)) {
          return this.setState({
            hasError: true,
            error: formattedMessages.errCheckPasswordStrong,
          });
        }
        if (!edits.password || !edits.password_check) {
          return this.setState({
            hasError: true,
            error: formattedMessages.errCheckPassword,
          });
        }
        if (!(edits.password === edits.password_check)) {
          return this.setState({
            hasError: true,
            error: formattedMessages.errCheckPasswordMatch,
          });
        }
      }

      // Check maximum SMS format
      if (edits.max_sms_per_day && edits.max_sms_per_day !== '') {
        if (!validateSms(edits.max_sms_per_day)) {
          return this.setState({
            hasError: true,
            error: formattedMessages.errTextMaxSms,
          });
        }
      }
      if (!edits.max_sms_per_day && edits.max_sms_per_day === '') {
        return this.setState({
          hasError: true,
          error: formattedMessages.errBlankMaxSms,
        });
      }

      // Request to backend
      this.setState(
        {
          isSaving: true,
          hasError: false,
          validated: true,
        },
        async () => {
          await userRequest.updateUser(
            this.props.user.customer_user_id,
            edits,
            errorHandler
          );

          this.props.callBack(true);
        }
      );
    } catch (e) {
      return this.setState({
        error: e.message,
        isSaving: false,
      });
    }
  };

  /*
    Handles clicking the Cancel button
  */
  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      showConfirmation: true,
    });
  };

  /*
    Handles cancelling a user
  */
  cancelUser = () => {
    this.setState(
      {
        isSaving: true,
        showConfirmation: false,
      },
      async () => {
        await userRequest.cancelUser(
          this.props.user.customer_user_id,
          this.props.errorHandler
        );

        this.props.callBack(true);
      }
    );
  };

  /*
   Handles clicking the Add Dedicated Number Button
 */
  handleAddDN = async () => {
    this.setState({ addDN: true });
  };

  toggleSearching() {
    let toggle = !this.state.isSearching;
    return this.setState({
      isSearching: toggle,
    });
  }

  /*
    Generates the Lander section
  */
  generateLander = () => {
    if (this.props.backMessage) {
      return (
        <Fragment>
          <p onClick={this.props.callBack} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{this.props.backMessage}</span>
          </p>
          <h4>{formattedMessages.editUser}</h4>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>Back to User List</span>
        </p>
        <h4>{formattedMessages.editUser}</h4>
      </Fragment>
    );
  };

  /*
    Generates the Cost Centre Select Options
  */
  generateCostCentreOptions = () => {
    let options = [];
    let { centres, edits } = this.state;
    let { user } = this.props;

    // For each Cost Centre, add option
    for (let i = 0; i < centres.length; i++) {
      if (centres[i].cost_centre_name === 'NotAssigned') {
        options.push(
          <option
            key={centres[i].cost_centre_id}
            value={centres[i].cost_centre_id}
          >
            {'No Cost Centre Assigned'}
          </option>
        );
      } else {
        options.push(
          <option
            key={centres[i].cost_centre_id}
            value={centres[i].cost_centre_id}
          >
            {centres[i].cost_centre_name}
          </option>
        );
      }
    }

    return (
      <Form.Group as={Row} controlId="cost_centre_id">
        <Form.Label column sm={2} className="info-label">
          Cost Center:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name="cost_centre_id"
            value={edits.cost_centre_id || user.cost_centre_id || ''}
          >
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateTextFormGroup = (
    label,
    controlId,
    isRequired,
    setType,
    defaultValue,
    godAdminOnly
  ) => {
    let { accessLevel, accessType } = this.props;
    let type = 'text';
    if (setType) type = setType;
    if (godAdminOnly && (accessLevel !== '3' || accessType !== '3'))
      return null;
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            required={isRequired || false}
            name={controlId}
            type={type}
            autoComplete="new-password"
            defaultValue={defaultValue}
          />
        </Col>
      </Form.Group>
    );
  };

  /**
   * function to generate checkbox
   * @param {String} label label for checkbox
   * @param {String} stateValue name of variable being altered
   * @returns The generated checkbox
   */
  generateCheckbox = (label, stateValue) => {
    let { accessLevel, accessType } = this.props;
    if (accessLevel === '3' && accessType === '3') {
      return (
        <Form.Group as={Row}>
          <Form.Label column sm={2} className="info-label">
            {label}:
          </Form.Label>
          <Col sm={8}>
            <FormCheck name={stateValue} />
          </Col>
        </Form.Group>
      );
    }
    return null;
  };

  /**
   * generates the user info for godadmin.
   * @returns JSX for the user info.
   */
  generateUserInfo = () => {
    let { user, accessLevel, accessType } = this.props;
    if (accessLevel === '3' && accessType === '3') {
      return (
        <Fragment>
          <Form.Group as={Row} controlId="user_type_name">
            <Form.Label column sm={2} className="user_type_name">
              Type - Class - Status:
            </Form.Label>
            <Col sm={8}>
              {`${user.user_type || ''} - ${user.user_class || ''} - ${
                user.user_status || ''
              }`}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="os_version">
            <Form.Label column sm={2} className="os_version">
              Operating System Version:
            </Form.Label>
            <Col sm={8}>{`${user.os_version || ''}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="mc_version">
            <Form.Label column sm={2} className="mc_version">
              Mail Client Version:
            </Form.Label>
            <Col sm={8}>{`${user.mc_version || ''}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sw_release">
            <Form.Label column sm={2} className="sw_release">
              Red Oxygen Software Release:
            </Form.Label>
            <Col sm={8}>{`${user.sw_release || ''}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="timezone">
            <Form.Label column sm={2} className="timezone">
              Timezone:
            </Form.Label>
            <Col sm={8}>{`${user.timezone || ''}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="dls_flag">
            <Form.Label column sm={2} className="dls_flag">
              Daylight Savings Flag:
            </Form.Label>
            <Col sm={8}>{`${user.dls_flag || ''}`}</Col>
          </Form.Group>
        </Fragment>
      );
    }
    return null;
  };

  generateForm = () => {
    let { edits } = this.state;
    let { user } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        <h3>{formattedMessages.userInformation}</h3>
        <h5>{user.user_email}</h5>
        <br />
        {this.generateTextFormGroup(
          'User Name',
          'user_name',
          false,
          null,
          user.user_name
        )}
        {this.generateTextFormGroup(
          'Password',
          'password',
          false,
          edits.showPassword ? null : 'password',
          '',
          true
        )}
        {this.generateTextFormGroup(
          'Confirm Password',
          'password_check',
          false,
          edits.showPassword ? null : 'password',
          '',
          true
        )}
        {this.generateCheckbox('Show Password', 'showPassword')}
        {this.generateTextFormGroup(
          'User Mobile',
          'user_tn',
          false,
          null,
          user.user_tn
        )}
        <Form.Group as={Row} controlId="country_code_id">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.editCountry}
          </Form.Label>
          <Col sm={8}>
            <CountryPicker
              className="form-control"
              defaultValue={edits.country_code_id || user.country_code_id}
            ></CountryPicker>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="language_id">
          <Form.Label column sm={2} className="info-label">
            Language:
          </Form.Label>
          <Col sm={8}>
            <LanguagePicker
              className="form-control"
              selectedLanguageId={edits.language_id || user.language_id}
            ></LanguagePicker>
          </Col>
        </Form.Group>
        {this.generateCostCentreOptions()}
        {this.generateTextFormGroup(
          'Maximum SMS Per Day',
          'max_sms_per_day',
          false,
          null,
          user.max_sms_per_day
        )}
        <Form.Group as={Row} controlId="receipt_flag">
          <Form.Label column sm={2} className="info-label">
            Receipt Flag{' '}
            <CustomTooltip tooltipText="If checked, this user will receive email receipts for sent messages." />{' '}
            :
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              defaultChecked={user.receipt_flag === 'Y'}
              name="receipt_flag"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="message_priority">
          <Form.Label column sm={2} className="info-label">
            Message Priority{' '}
            <CustomTooltip tooltipText="Determines sending priority for user." />{' '}
            :
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              name="message_priority"
              defaultValue={edits.message_priority || user.message_priority}
            >
              <option value="0">Low</option>
              <option value="1">Medium</option>
              <option value="2">High</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="http_reply_flag">
          <Form.Label column sm={2} className="info-label">
            HTTP Reply Flag:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              defaultChecked={user.http_reply_flag === 'Y'}
              name="http_reply_flag"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="last_sms_text_flag">
          <Form.Label column sm={2} className="info-label">
            Message Threading{' '}
            <CustomTooltip tooltipText="Threaded messages display both sides of a conversation in order." />{' '}
            :
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              defaultChecked={user.last_sms_text_flag === 'Y'}
              name="last_sms_text_flag"
            />
          </Col>
        </Form.Group>
        {this.generateUserInfo()}
        {this.generateErrorText()}
      </Form>
    );
  };

  /*
    Handles when a back button is clicked in one of the child components
  */
  handleBackClick = async () => {
    let defaultState = {
      error: null,
      centres: [],
      dns: [],
      edits: {},
      addDN: false,
      isSaving: false,
    };
    let { errorHandler, user } = this.props;

    console.log('handleBackCLick');
    console.log(user);

    let dedicatedNumbers = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    let dns = dedicatedNumbers;

    defaultState.dns = dns;
    console.log(dns);
    // Return to default state
    return this.setState(defaultState);
  };

  generateDedicatedNumbersTable = () => {
    let htmlRows = [];
    let { dns } = this.state;
    if (dns !== undefined) {
      dns.forEach((item) => {
        htmlRows.push(
          <tr style={{ textAlign: 'center' }}>
            <td>{item.reply_tn}</td>
          </tr>
        );
      });
    }

    return (
      <Table style={{ marginTop: '20px' }}>
        <thead>
          <tr>
            <th className="medium tableTop column-left text-center">
              Dedicated Number
            </th>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  };

  render() {
    let { addDN } = this.state;

    // Add User
    if (addDN) {
      return (
        <AddDN
          callBack={this.handleBackClick}
          user={this.props.user}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    return (
      <Fragment>
        {this.generateLander()}
        {this.generateForm()}
        {this.generateFormButtons()}
        {this.showConfirmationDialog()}
        <br />
        {this.generateDedicatedNumbersTable()}
        {this.generateDNButton()}
      </Fragment>
    );
  }
}
