/*
  RecoverPassword/index.js - Root Password Recovery Component

  Author: Elle Dunbar
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';

// COMPONENTS
import LoaderButton from '../../components/LoaderButton';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../Register/Register.css';

// IMGS
import roxLogo from '../../images/Logo-RO4.gif';

// NETWORK
import RecoverPasswordRequest from './RecoverPasswordRequest';
import { validateEmail } from '../../utils/validate';
const recoverPasswordRequest = new RecoverPasswordRequest();

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      hasRegistered: false,
      hasError: false,
      errorText: '',
    };
  }

  /*
    Validates form
  */
  isFormValid = () => {
    return this.state.email.length > 0;
  };

  /*
    Handles setting state for form inputs
  */
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  /*
    Sets error text if in an error state, else returns nulls
  */
  setError = () => {
    if (!this.state.hasError) return;

    return <div className="errorText">{this.state.errorText}</div>;
  };

  /**
   * Goes back to the login page.
   */
  backToLogin = () => {
    this.props.navigate('/');
  };

  /*
    Handles form submission
  */
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
      hasError: false,
    });

    try {
      if (!validateEmail(this.state.email)) {
        return this.setState({
          hasError: true,
          errorText: 'The email provided is not in a valid format.',
          isLoading: false,
        });
      }

      //todo: add endpoint call here
      // Make request to start Customer password recovery
      let error = await recoverPasswordRequest.recoverPassword(
        this.state.email,
        this.props.errorHandler
      );
      // If no error, go to next state
      if (!error) {
        return this.setState({
          hasRegistered: true,
          isLoading: false,
          hasError: false,
        });
      }

      return this.setState({
        hasError: true,
        errorText: error.error,
        isLoading: false,
      });
    } catch (e) {
      console.log('Network error:', e);
      return this.setState({
        hasError: true,
        errorText: 'Network Error',
        isLoading: false,
      });
    }
  };

  /*
    Render the Register Form components based on state
  */
  renderForm() {
    let successText = formattedMessages.successText;

    // User has completed the Registration process
    if (this.state.hasRegistered) {
      return (
        <div className="registerSuccess">
          <img src={roxLogo} alt="Red Oxygen Logo" />
          <h5>
            <br />
            {successText}
          </h5>
          {formattedMessages.emailSent} {this.state.email}
        </div>
      );
    }
    // User has not completed the Registration process
    else {
      return (
        <form onSubmit={this.handleSubmit} className="registerForm">
          <br />
          <img
            src={roxLogo}
            alt="Red Oxygen Logo"
            style={{ marginLeft: 'calc(50% - 85px)' }}
          />
          <br />
          <br />
          <b>{formattedMessages.recoverPasswordTitle}</b>
          <FormGroup controlId="email" bssize="large">
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          {this.setError()}
          <LoaderButton
            block
            bssize="large"
            disabled={!this.isFormValid()}
            type="submit"
            isLoading={this.state.isLoading}
            text={formattedMessages.recoveryButtonText}
            loadingText={formattedMessages.loading}
          />
        </form>
      );
    }
  }

  render() {
    return (
      <div className="Signup">
        <div className="customerVerify customerVerify-no-padding">
          <p
            onClick={this.backToLogin}
            className="backLink backLinkForgotPassword"
          >
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backToLogin}</span>
          </p>
          <div className="customerVerify-padding">{this.renderForm()}</div>
        </div>
        <br />
      </div>
    );
  }
}
