/*
  All.js - Admin ALL Device List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';

// NETWORK
import DeviceRequest from './DeviceRequest';

// COMPONENTS
import formattedMessages from './FormattedMessages';

// INIT
const deviceRequest = new DeviceRequest();

export default class DeviceAllList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - errorHandler: A function to pass errors to
        - addNewDevice: 
        - showSingleDevice: 
    */

    this.state = {
      devices: [],
      loading: true,
      isCurrentlyDedicated: false,
      isCurrentlyPooled: false,
      paginatedDevices: [],
      pageNumber: 1,
      pageLength: config.defaultPL,
    };
  }

  async componentDidMount() {
    const devices = await deviceRequest.getAllDevices(
      null,
      null,
      this.props.errorHandler
    );
    console.log('Got ALL Devices:', devices);
    return this.setState(
      {
        devices: devices,
        loading: false,
        pageNumber: 1,
      },
      this.setPaginatedResults
    );
  }

  handleCheckbox = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      this.getDevices
    );
  };

  getDevices = async () => {
    let { isCurrentlyDedicated, isCurrentlyPooled } = this.state;
    this.setState({ loading: true });
    let results = await deviceRequest.getAllDevices(
      isCurrentlyDedicated,
      isCurrentlyPooled,
      this.props.errorHandler
    );
    console.log('filtered device', results.length, results);
    this.resetFunction();
    return this.setState(
      {
        devices: results,
        loading: false,
        pageNumber: 1,
      },
      this.setPaginatedResults
    );
  };

  generateCheckbox = () => {
    return (
      <div>
        <Form>
          <Form.Check
            inline
            type="checkbox"
            name="isCurrentlyDedicated"
            id="a"
            label="Currently Dedicated"
            onChange={(e) => this.handleCheckbox(e)}
          />
          <Form.Check
            inline
            type="checkbox"
            id="b"
            name="isCurrentlyPooled"
            label="Currently Pooled"
            onChange={(e) => this.handleCheckbox(e)}
          />
        </Form>
      </div>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="DeviceAll" className="lander">
        <p onClick={() => this.props.backButtonClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.back}</span>
        </p>
        <div className="flex">
          <h3>All Devices</h3>
          <Button
            className="flexRight maxHeight btn btn-primary"
            onClick={this.props.addNewDevice}
          >
            {formattedMessages.addNewText}
          </Button>
        </div>
      </div>
    );
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { devices, pageNumber, pageLength } = this.state;
    const newList = devices.slice(
      (pageNumber - 1) * pageLength,
      pageNumber * pageLength
    );
    this.setState({
      paginatedDevices: newList,
    });
  };

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  generateTable() {
    let htmlRows = [];
    let { paginatedDevices } = this.state;

    if (this.state.loading)
      return (
        <div key="DeviceAllList" className="table">
          {formattedMessages.loading}
        </div>
      );

    if (paginatedDevices.length === 0) return <div>No Devices Retrieved.</div>;

    for (let i = 0; i < paginatedDevices.length; i++) {
      const {
        device_id,
        device_name,
        priority,
        device_type,
        device_status,
        reply_tn,
        device_status_meaning,
        device_type_meaning,
      } = paginatedDevices[i];

      const deviceId = device_id?.substr(0, 8) || 'missing';
      htmlRows.push(
        <tr key={`list${device_id}`} id={device_id}>
          <td className="textLeft" width="10%">
            <span className="small">{deviceId}</span>
          </td>
          <td className="textLeft" width="50%">
            <span
              className="blue"
              onClick={() =>
                this.props.showSingleDevice(paginatedDevices[i], true)
              }
            >
              {device_name}
            </span>
          </td>
          <td className="textLeft" width="10%">
            <span>{priority}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{device_type_meaning || device_type}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{device_status_meaning || device_status}</span>
          </td>
          <td className="textLeft" width="10%">
            <span>{reply_tn || 'N/A'}</span>
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Table className="table-new">
          <thead>
            <tr>
              <td className="medium tableTop textLeft">
                {formattedMessages.deviceId}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.deviceName}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.priority}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.type}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.status}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.replyTn}
              </td>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
        <Paginator
          itemCount={this.state.devices.length}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          changePageFunction={(ref) => (this.resetFunction = ref)}
        />
      </div>
    );
  }

  render() {
    return (
      <div key="DeviceAllList" className="table">
        {this.generateLander()}
        {this.generateCheckbox()}
        {this.generateTable()}
      </div>
    );
  }
}
