import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  listOfCustomers: (
    <FormattedMessage
      id="AdminCustomer.ListOfCustomers"
      defaultMessage="List of Customers"
    />
  ),
  typeClassStatus: (
    <FormattedMessage
      id="Global.TypeClassStatus"
      defaultMessage="Type - Class - Status"
    />
  ),
  nameText: <FormattedMessage id="ReportsList.Name" defaultMessage="Name" />,
  statusText: (
    <FormattedMessage id="ReportsList.Status" defaultMessage="Status" />
  ),
  addNewText: (
    <FormattedMessage id="Global.AddNewText" defaultMessage="Add New" />
  ),
  backText: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="Back To Customer List"
    />
  ),
  viewInvoices: (
    <FormattedMessage id="Global.ViewInvoices" defaultMessage="View Invoices" />
  ),
  paymentBackText: (
    <FormattedMessage
      id="Websms.PaymentBackText"
      defaultMessage="Back To Customer View"
    />
  ),
  addReplacePaymentMethod: (
    <FormattedMessage
      id="AdminCustomer.AddReplacePaymentMethod"
      defaultMessage="Add or Replace Customer Payment Method"
    />
  ),
  createProvider: (
    <FormattedMessage
      id="AdminProvider.CreateProvider"
      defaultMessage="Create Provider"
    />
  ),
  createCustomer: (
    <FormattedMessage
      id="AdminProvider.CreateCustomer"
      defaultMessage="Create Customer"
    />
  ),
  businessName: (
    <FormattedMessage
      id="AdminProvider.BusinessName"
      defaultMessage="* Business Name"
    />
  ),
  tariffPlan: (
    <FormattedMessage
      id="AdminProvider.TariffPlan"
      defaultMessage="Tariff Plan"
    />
  ),
  trial: <FormattedMessage id="AdminProvider.Trial" defaultMessage="Trial" />,
  active: (
    <FormattedMessage id="AdminProvider.Active" defaultMessage="Active" />
  ),
  inactive: (
    <FormattedMessage id="AdminProvider.Inactive" defaultMessage="Inactive" />
  ),
  cancelled: (
    <FormattedMessage id="AdminProvider.Cancelled" defaultMessage="Cancelled" />
  ),
  pendingCancelled: (
    <FormattedMessage
      id="AdminProvider.PendingCancelled"
      defaultMessage="Pending Cancelled"
    />
  ),
  suspended: (
    <FormattedMessage id="AdminProvider.Suspended" defaultMessage="Suspended" />
  ),
  trialNA: (
    <FormattedMessage id="AdminProvider.TrialNA" defaultMessage="Trial - N/A" />
  ),
  postPaidInvoice: (
    <FormattedMessage
      id="AdminProvider.PostPaidInvoice"
      defaultMessage="Post Paid - Invoice"
    />
  ),
  postPaidBusiness: (
    <FormattedMessage
      id="AdminProvider.PostPaidBusiness"
      defaultMessage="Post Paid - Business"
    />
  ),
  prePaidBatch: (
    <FormattedMessage
      id="AdminProvider.PrePaidBatch"
      defaultMessage="Pre Paid - Batch"
    />
  ),
  prePaidBusiness: (
    <FormattedMessage
      id="AdminProvider.PrePaidBusiness"
      defaultMessage="Pre Paid - Business"
    />
  ),
  country: (
    <FormattedMessage id="AdminProvider.Country" defaultMessage="Country" />
  ),
  loading: (
    <FormattedMessage id="AdminProvider.Loading" defaultMessage="Loading..." />
  ),
  noCampaignFound: (
    <FormattedMessage
      id="AdminProvider.noCampaignFound"
      defaultMessage="No Campaigns found for this customer."
    />
  ),
  poolNumbers: (
    <FormattedMessage
      id="AdminProvider.PoolNumbers"
      defaultMessage="Pool Numbers"
    />
  ),
  addPN: (
    <FormattedMessage id="Global.AddPn" defaultMessage="Manage Pool Numbers" />
  ),
  noPoolNumbers: (
    <FormattedMessage
      id="Global.NoPoolNumbers"
      defaultMessage="No Pool Numbers found for this customer"
    />
  ),
  campaign: <FormattedMessage id="Global.Campaign" defaultMessage="Campaign" />,
  poolNumber: (
    <FormattedMessage id="Global.PoolNumber" defaultMessage="Pool Number" />
  ),
  release: <FormattedMessage id="Global.Release" defaultMessage="Release?" />,
  languageOnWebPages: (
    <FormattedMessage
      id="AdminProvider.LanguageOnWebPages"
      defaultMessage="Language on Web Pages"
    />
  ),
  english: (
    <FormattedMessage id="AdminProvider.English" defaultMessage="English" />
  ),
  polish: (
    <FormattedMessage id="AdminProvider.Polish" defaultMessage="Polish" />
  ),
  activeCreation: (
    <FormattedMessage
      id="AdminProvider.ActiveCreation"
      defaultMessage="Active Creation"
    />
  ),
  xeroAccount: (
    <FormattedMessage
      id="AdminProvider.XeroAccount"
      defaultMessage="Xero Account"
    />
  ),
  aUSales: (
    <FormattedMessage id="AdminProvider.AUSales" defaultMessage="AU Sales" />
  ),
  requiredField: (
    <FormattedMessage
      id="AdminProvider.RequiredField"
      defaultMessage="* Indicates a required field"
    />
  ),
  cancel: (
    <FormattedMessage id="AdminProvider.Cancel" defaultMessage="Cancel" />
  ),
  edit: <FormattedMessage id="AdminProvider.Edit" defaultMessage="Edit" />,
  customerReferenceNo: (
    <FormattedMessage
      id="AdminProvider.CustomerReferenceNo"
      defaultMessage="Customer Reference No"
    />
  ),
  defaultOriginator: (
    <FormattedMessage
      id="AdminProvider.DefaultOriginator"
      defaultMessage="Default Originator"
    />
  ),
  typeClass: (
    <FormattedMessage
      id="AdminProvider.TypeClass"
      defaultMessage="Type - Class"
    />
  ),
  status: (
    <FormattedMessage id="AdminProvider.Status" defaultMessage="Status" />
  ),
  salesAgent: (
    <FormattedMessage
      id="AdminProvider.SalesAgent"
      defaultMessage="Sales Agent"
    />
  ),
  contractFormId: (
    <FormattedMessage
      id="AdminProvider.ContractFormId"
      defaultMessage="Contract Form Id"
    />
  ),
  redAlertEnabled: (
    <FormattedMessage
      id="AdminProvider.RedAlertEnabled"
      defaultMessage="Red Alert Enabled"
    />
  ),
  gSTApplicable: (
    <FormattedMessage
      id="AdminProvider.GSTApplicable"
      defaultMessage="GST Applicable"
    />
  ),
  oMSFlag: (
    <FormattedMessage
      id="AdminProvider.OMSFlag"
      defaultMessage="Outlook Mobile Service Flag"
    />
  ),
  receiptFlag: (
    <FormattedMessage
      id="AdminProvider.ReceiptFlag"
      defaultMessage="Receipt Flag"
    />
  ),
  employeeTrustFlag: (
    <FormattedMessage
      id="AdminProvider.employeeTrustFlag"
      defaultMessage="Employee Trust Flag"
    />
  ),
  messagePriority: (
    <FormattedMessage
      id="AdminProvider.messagePriority"
      defaultMessage="Message Priority"
    />
  ),
  low: <FormattedMessage id="AdminProvider.Low" defaultMessage="Low" />,
  medium: (
    <FormattedMessage id="AdminProvider.Medium" defaultMessage="Medium" />
  ),
  high: <FormattedMessage id="AdminProvider.High" defaultMessage="High" />,
  prepaidCredits: (
    <FormattedMessage
      id="AdminProvider.prepaidCredits"
      defaultMessage="Prepaid Credits"
    />
  ),
  hTTPReplyTemplate: (
    <FormattedMessage
      id="AdminProvider.HTTPReplyTemplate"
      defaultMessage="HTTP Reply Template"
    />
  ),
  hTTPReplyURL: (
    <FormattedMessage
      id="AdminProvider.HTTPReplyURL"
      defaultMessage="HTTP Reply URL"
    />
  ),
  save: <FormattedMessage id="Global.Save" defaultMessage="Save" />,
  saving: <FormattedMessage id="Global.Saving" defaultMessage="Saving..." />,
  dangerText: (
    <FormattedMessage
      id="Global.DangerText"
      defaultMessage="For Default Originator: Limit of 11 chars, or 15 chars with a '+' at the start."
    />
  ),
  businessInformation: (
    <FormattedMessage
      id="Global.BusinessInformation"
      defaultMessage="Business Information"
    />
  ),
  customerABN: (
    <FormattedMessage
      id="Global.CustomerABN"
      defaultMessage="Customer No (ABN)"
    />
  ),
  contactInformation: (
    <FormattedMessage
      id="Global.ContactInformation"
      defaultMessage="Contact Information"
    />
  ),
  phone: <FormattedMessage id="Global.Phone" defaultMessage="Phone" />,
  fax: <FormattedMessage id="Global.Fax" defaultMessage="Fax" />,
  streetAddress: (
    <FormattedMessage
      id="Global.StreetAddress"
      defaultMessage="Street Address"
    />
  ),
  continued: (
    <FormattedMessage id="Global.Continued" defaultMessage="Continued..." />
  ),
  city: <FormattedMessage id="Global.City" defaultMessage="City" />,
  state: <FormattedMessage id="Global.State" defaultMessage="State" />,
  postcode: <FormattedMessage id="Global.Postcode" defaultMessage="Postcode" />,
  cardType: (
    <FormattedMessage id="Global.cardType" defaultMessage="Card Type" />
  ),
  paymentMethodSuccess: (
    <FormattedMessage
      id="Global.paymentMethodSuccess"
      defaultMessage="A new payment method has been successfully added to your Customer account."
    />
  ),
  paymentMethodNotes: (
    <FormattedMessage
      id="Global.paymentMethodNotes"
      defaultMessage="Note: If you had an old payment method it is replaced with the new details."
    />
  ),
  listOfComments: (
    <FormattedMessage id="Global.listOfComments" defaultMessage="Comments" />
  ),
  accountInformation: (
    <FormattedMessage
      id="Global.accountInformation"
      defaultMessage="Account Information"
    />
  ),
  attachments: (
    <FormattedMessage id="Global.attachments" defaultMessage="Attachments" />
  ),
  addComment: (
    <FormattedMessage id="Global.addComment" defaultMessage="Add Comment" />
  ),
  tariffConfirmation: (
    <FormattedMessage
      id="Global.tariffConfirmation"
      defaultMessage="Tariff Confirmation"
    />
  ),
  primaryContactDetails: (
    <FormattedMessage
      id="Global.primaryContactDetails"
      defaultMessage="Primary Contact Details"
    />
  ),
  meetingMinutes: (
    <FormattedMessage
      id="Global.meetingMinutes"
      defaultMessage="Meeting Minutes"
    />
  ),
  other: <FormattedMessage id="Global.other" defaultMessage="Other" />,
  chooseFile: (
    <FormattedMessage id="Global.chooseFile" defaultMessage="Choose File..." />
  ),
  addAttachment: (
    <FormattedMessage
      id="Global.addAttachment"
      defaultMessage="Add Attachment"
    />
  ),
  noResultsFound: (
    <FormattedMessage
      id="Global.noResultsFound"
      defaultMessage="No Results Found"
    />
  ),
  selectTheFileToImport: (
    <FormattedMessage
      id="Global.selectTheFileToImport"
      defaultMessage="Select the file to import"
    />
  ),
  uploadType: (
    <FormattedMessage id="Global.uploadType" defaultMessage="Upload Type" />
  ),
  none: <FormattedMessage id="Global.none" defaultMessage="None" />,
  saveChanges: (
    <FormattedMessage id="Global.saveChanges" defaultMessage="Save Changes" />
  ),
  rO4HttpReplyTxt: (
    <FormattedMessage
      id="Global.rO4HttpReplyTxt"
      defaultMessage="RO4HttpReply.txt"
    />
  ),
  rO4HttpReplyFormDataTxt: (
    <FormattedMessage
      id="Global.rO4HttpReplyFormDataTxt"
      defaultMessage="RO4HttpReplyFormData.txt"
    />
  ),
  noData: <FormattedMessage id="Global.noData" defaultMessage="No Data" />,
  modalCancel: (
    <FormattedMessage id="Global.modalCancel" defaultMessage="Cancel" />
  ),
  modalYes: <FormattedMessage id="Global.modalYes" defaultMessage="Yes" />,
  modalWarning: (
    <FormattedMessage id="Global.modalWarning" defaultMessage="Warning" />
  ),
  modalBody: (
    <FormattedMessage
      id="Global.modalBody"
      defaultMessage="A number you want to release appears to be a legacy number based on the name. Are you sure you want to release that number?"
    />
  ),
  authorizedAdmin: (
    <FormattedMessage
      id="Global.AuthorizedAdmin"
      defaultMessage="Authorized Administrator"
    />
  ),
  name: <FormattedMessage id="Global.Name" defaultMessage="* Name:" />,
  mobilePhoneNumber: (
    <FormattedMessage
      id="Global.MobilePhoneNumber"
      defaultMessage="Mobile Phone Number:"
    />
  ),
  emailAddress: (
    <FormattedMessage
      id="Global.EmailAddress"
      defaultMessage="* Email Address:"
    />
  ),
};

export default FormattedMessages;
