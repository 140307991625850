/*
  CustomerRequest.js - CustomerRequest Networking File
  
  Contains networking functionality for Admin Customer Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class CustomerRequest {
  /*
    Send a request to create a new Customer given a Customer object
    from a form
  */
  createNewCustomer = async (newCustomer, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/`;
      const body = JSON.stringify(newCustomer);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets a list of Sales Agents for the given Provider Id 
  */
  getSalesAgents = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/agent?select=single`;
      const options = {
        method: 'GET',
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Fetches extra data for creating a new Customer.
    - Tariff Plans options
    - Class - Type options
  */
  getCustomerExtras = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/extras`;
      const options = {
        method: 'GET',
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /*
    Fetches campaign data for the customer.
  */
  getCustomerCampaignData = async (customerId, errorHandler) => {
    try {
      const url =
        `${config.baseServerURL}/admin/customer/campaigns/` + customerId;
      const options = {
        method: 'GET',
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /*
    Fetches pool number data for the customer.
  */
  getPoolNumbers = async (customerId, errorHandler) => {
    try {
      const url =
        `${config.baseServerURL}/admin/customer/numbers/` + customerId;
      const options = {
        method: 'GET',
      };

      // Make Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /*
    Calls TWILIO api to request 20 possible numbers for a given Country Code and Prefix
  */

  getTwilioProvisional = async (data, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/twilio/number/search?countryCode=${data.country}`;

      if (data.prefix !== undefined) {
        url += `&areaCode=${data.prefix}`;
      }
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  provisionTwilioNumbers = async (data, errorHandler) => {
    try {
      console.log('provisionTwilioNumbers called');
      let url = `${config.baseServerURL}/admin/customer/numbers/provision`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      console.log('options:', options);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('responseJSON:', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  releaseTwilioNumbers = async (data, errorHandler) => {
    try {
      console.log('releaseTwilioNumbers called');
      let url = `${config.baseServerURL}/admin/customer/numbers/release`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      console.log('JSON.stringify(data):', JSON.stringify(data));
      console.log('options:', options);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log('response', response);
      return response;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  changeTwilioNumbers = async (data, customerId, errorHandler) => {
    try {
      console.log('changeTwilioNumbers called');
      let url = `${config.baseServerURL}/admin/customer/numbers/change`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ numbers: data, customerId: customerId }),
      };

      console.log('JSON.stringify(data):', JSON.stringify(data));
      console.log('options:', options);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log('response', response);
      return response;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
